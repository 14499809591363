import * as React from "react"
import styled from "styled-components"
import AppMenu from "../menu/app-container"
import Logo from "./logo"

const Box = styled.header`
  position: absolute;
  left: 0;
  /* top: 2.5rem; */
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Ele = () => {
  return (
    <Box>
      <Logo />
      <AppMenu />
    </Box>
  )
}
export default Ele
