import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  width: 100%;
  //margin: 0 auto;
  margin-top: -330px;
  z-index: 300;
  position: relative;

  @media (max-width: 766px) {
    width: 100%;
    padding: 0 1.5rem;
  }
`

const Ele = ({ children }) => {
  return <Box>{children}</Box>
}
export default Ele
